<template>
  <f7-page class="scan-page">
    <f7-navbar back-link="Back"> </f7-navbar>

    <section class="custom-container">
      <div class="container">
          <p><img src="@/assets/images/wechat.png" loading="lazy"></p>
          <h1>Processing...</h1>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, inject} from "vue";
import { useStore } from "@/store";
import { post, get } from "@/utils/axios";

export default defineComponent({
  name: "WechatRedictPage",
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation")
    const store = useStore();

    onMounted(async () => {
      if(props?.f7route?.query?.key){
        
      }
    });
    return {};
  },
});
</script>

<style scoped>
  .container > h1{
    text-align: center;
  }
  section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  p{
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  .container > p > img{
    width: 100px;
  }
</style>